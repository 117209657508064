/* eslint-disable multiline-ternary */
import React from 'react';
import styled from 'styled-components';
import SectionOne from './section-one';
import SectionTwoThree from './section-two-three';
import SectionFour from './section-four';
import _ from 'lodash';

function SectionPrideCampaign({ sectionPrideData, dataSetSectionPride }: any) {
  return (
    <SectionWrapper className='section-pride-campaign'>
      {!_.isEmpty(dataSetSectionPride?.sectionOne) && !_.isEmpty(sectionPrideData?.sectionOne) ? (
        <SectionOne dataSet={dataSetSectionPride?.sectionOne} data={sectionPrideData?.sectionOne}></SectionOne>
      ) : null}
      {(!_.isEmpty(dataSetSectionPride?.sectionTwo) && !_.isEmpty(sectionPrideData?.sectionTwo)) || (!_.isEmpty(dataSetSectionPride?.sectionThree) && !_.isEmpty(sectionPrideData?.sectionThree)) ? (
        <SectionTwoThree
          dataSet2={dataSetSectionPride?.sectionTwo}
          dataSet3={dataSetSectionPride?.sectionThree}
          data2={sectionPrideData?.sectionTwo}
          data3={sectionPrideData?.sectionThree}
        ></SectionTwoThree>
      ) : null}
      {!_.isEmpty(dataSetSectionPride?.sectionFour) ? <SectionFour dataSet={dataSetSectionPride?.sectionFour}></SectionFour> : null}
    </SectionWrapper>
  );
}
const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
`;
export default SectionPrideCampaign;
