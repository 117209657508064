import { NextPage } from 'next';
import { useState, useEffect } from 'react';
import { GET } from 'src/services';
import useSWR from 'swr';
import _ from 'lodash';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { convertDatasets } from 'src/utils/datasets';
import styled from 'styled-components';
import CardV from 'src/components/cards/card-v';
import { APP_IMG } from 'src/constants';
import useResponsive from 'src/utils/device';
type Props = {
  eventDataLayer?: IDataLayerGlobal;
};
const SectionEvent: NextPage<Props> = ({ eventDataLayer }) => {
  const responsive = useResponsive();
  const [dataSet, setDataSet]: any = useState([]);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [time, setTime]: any = useState();

  useEffect(() => {
    const resData = async () => {
      const res = await GET('/api/datasets');
      const covertData = convertDatasets(res, 'awards-index', false);
      setDataSet(covertData);
    };

    // call the function
    resData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    setTime(convertDatasets(dataSet[0], 'เวลาจบ', true));
  }, [dataSet]);

  useEffect(() => {
    const countDownDate = new Date(time)?.getTime();
    const updateState = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      if (distance < 0) {
        return () => clearInterval(updateLoop);
      }
      // calculate
      // setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));

      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;

      const days = Math.floor(distance / _day);
      const hours = Math.floor((distance % _day) / _hour);
      const minutes = Math.floor((distance % _hour) / _minute);
      const seconds = Math.floor((distance % _minute) / _second);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    };

    const updateLoop = setInterval(updateState, 1000);
    return () => clearInterval(updateLoop);
  }, [time]);

  const fetcher = (url: any) => GET(url, false, true);
  const keys = convertDatasets(dataSet[0], 'คีย์ข้อมูลที่1', true);
  let linkMore = '';
  let tag = [];
  let keysTag = '';
  if (keys === 'tag') {
    if (convertDatasets(dataSet[0], 'ข้อมูลที่1', true).indexOf('/') !== -1) {
      tag = convertDatasets(dataSet[0], 'ข้อมูลที่1', true).split('/');
      keysTag = tag[1];
    } else {
      keysTag = convertDatasets(dataSet[0], 'ข้อมูลที่1', true);
    }

    linkMore = `/tags/${convertDatasets(dataSet[0], 'ข้อมูลที่1', true)}`;
  } else if (keys === 'category') {
    linkMore = `/category${convertDatasets(dataSet[0], 'ข้อมูลที่1', true)}`;
  }
  const { data } = useSWR(`/api/search/tag?name=${keysTag}`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  const dataCate = useSWR(`/api/v1.0/categories${convertDatasets(dataSet[0], 'ข้อมูลที่1', true)}`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  if (_.isEmpty(dataSet)) return null;

  const blockRes = keys === 'tag' ? !_.isEmpty(data?.data) && data : !_.isEmpty(dataCate?.data) && dataCate?.data;

  return (
    <>
      {blockRes && (
        <SectionWrapper className='wrapper-1'>
          <div className='container detail-header'>
            <img src={convertDatasets(dataSet[0], 'รูปที่1', true)} alt='รูปที่1' title='รูปภาพโลโก้' aria-label='รูปภาพโลโก้' width='100%' height='100%' />
            <div className='text-block'>{convertDatasets(dataSet[0], 'ข้อความที่1', true)}</div>
          </div>
          <div className='container grid-card-section'>
            {/* {blockRes && ( */}
            <div className='block-item-1'>
              {/* <SectionHeader className='white-color' data={block1?.title} link={block1?.link} /> */}
              <div className='card-list'>
                <div className='card-block-1'>
                  {_.map(blockRes?.data?.slice(0, 1), (item, index) => (
                    <div className={`card-item --item-${index + 1}`} key={index}>
                      <CardV
                        key={index}
                        data={item}
                        showBlurb={false}
                        showTitle={true}
                        showCountview={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          data: {
                            block: '1',
                            heading: blockRes?.title,
                            title: item?.title,
                            carousel: index + 1
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className='card-block-2'>
                  <div className='block-awards'>
                    {responsive.isMobileMini && (
                      <>
                        <img className='image-awards-mobile' src={convertDatasets(dataSet[0], 'รูปที่2', true)} alt='รูปที่1' title='รูปภาพโลโก้' aria-label='รูปภาพโลโก้' width='100%' height='100%' />
                        <div className='time-awards'>
                          <div>
                            <img className='image-logo' src={convertDatasets(dataSet[0], 'โลโก้', true)} alt='รูปที่1' title='seiko' aria-label='seiko' width='100%' height='100%' />
                            <div className='border-bottom logo' />
                          </div>
                          <p className='paragraph-1'>COUNTDOWN</p>
                          <div className='image-awards-mobile'>
                            {/* <img className='image-awards' src={convertDatasets(dataSet[0], 'รูปที่2', true)} alt='รูปที่1' title='รูปภาพโลโก้' aria-label='รูปภาพโลโก้' width='100%' height='100%' /> */}
                            <div className='time-countdown'>
                              <div className='day'>
                                <p className='paragraph-2'>{days}</p>
                                <p className='paragraph-3'>Day</p>
                              </div>
                              <div>
                                <p className='paragraph-2'>{hours}</p>
                                <p className='paragraph-3'>Hours</p>
                              </div>
                              <div>
                                <p className='paragraph-2'>{minutes}</p>
                                <p className='paragraph-3'>Mins</p>
                              </div>
                              <div>
                                <p className='paragraph-2'>{seconds}</p>
                                <p className='paragraph-3'>Secs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {!responsive.isMobileMini && (
                      <>
                        <img className='image-awards' src={convertDatasets(dataSet[0], 'รูปที่2', true)} alt='รูปที่1' title='รูปภาพโลโก้' aria-label='รูปภาพโลโก้' width='100%' height='100%' />
                        <div className='time-awards'>
                          <div>
                            <img className='image-logo' src={convertDatasets(dataSet[0], 'โลโก้', true)} alt='รูปที่1' title='seiko' aria-label='seiko' width='100%' height='100%' />
                            <div className='border-bottom logo' />
                          </div>
                          <p className='paragraph-1'>COUNTDOWN</p>
                          <div className='time-countdown'>
                            <div className='day'>
                              <p className='paragraph-2'>{days}</p>
                              <p className='paragraph-3'>Day</p>
                            </div>
                            <div>
                              <p className='paragraph-2'>{hours}</p>
                              <p className='paragraph-3'>Hours</p>
                            </div>
                            <div>
                              <p className='paragraph-2'>{minutes}</p>
                              <p className='paragraph-3'>Mins</p>
                            </div>
                            <div>
                              <p className='paragraph-2'>{seconds}</p>
                              <p className='paragraph-3'>Secs</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='block-detail'>
                    <h3 className='title-awards'>{convertDatasets(dataSet[0], 'ข้อความที่2', true)} </h3>
                    <h4 className='desc-awards'>{convertDatasets(dataSet[0], 'รายละเอียดที่2', true)} </h4>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            {/* {blockRes && ( */}
            <div className='block-item-2'>
              {/* <SectionHeader className='white-color' data={block2?.title} link={block2?.link} /> */}
              <div className='read-more'>
                <h2>{convertDatasets(dataSet[0], 'หัวข้อ', true)}</h2>
                <div className='read-more-text'>
                  <a href={linkMore}>ดูทั้งหมด</a>
                </div>
              </div>
              <div className='card-list'>
                {_.map(blockRes?.data?.slice(1, 4), (item, index) => (
                  <div className='card-item' key={index}>
                    <CardV
                      key={index}
                      data={item}
                      showBlurb={false}
                      showTitle={true}
                      showCountview={false}
                      // ! DATALAYER
                      eventDataLayer={{
                        ...eventDataLayer,
                        data: {
                          block: '1',
                          heading: blockRes?.title,
                          title: item?.title,
                          carousel: index + 1
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}
            <div className='border-top'></div>
            <div className='border-bottom'></div>
          </div>
        </SectionWrapper>
      )}
    </>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 30px 0;
  margin-top: 30px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: url(${APP_IMG}/images/tppa/image-dot.png);
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.3;
    z-index: -1;
    @media (max-width: 768px) {
      height: 150px;
    }
  }
  .detail-header {
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 690px) {
      flex-direction: column;
    }
    img {
      width: 50%;
      @media (max-width: 690px) {
        width: 100%;
      }
    }
    .text-block {
      width: 50%;
      @media (max-width: 690px) {
        width: 100%;
      }
    }
  }
  .grid-card-section {
    margin-top: 20px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-template-areas:
      'border-top border-top'
      'block-item-1 block-item-1'
      'block-item-2 block-item-2'
      'border-bottom border-bottom';
    @media (max-width: 690px) {
      margin-top: 0;
    }
    .border-bottom {
      grid-area: border-bottom;
      width: 100%;
      height: 10px;
      background: rgb(238, 57, 91);
      background: linear-gradient(90deg, rgba(238, 57, 91, 1) 50%, rgba(0, 145, 209, 1) 50%);
      &.logo {
        height: 3px;
      }
      @media (max-width: 768px) {
        height: 5px;
      }
    }
    .border-top {
      grid-area: border-top;
      width: 100%;
      height: 2px;
      background: #999999;
    }

    .card-detail {
      border: unset;
    }
    .block-item-1 {
      grid-area: block-item-1;
      .card-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 'card-block-1 card-block-1 card-block-2';
        grid-gap: 20px;
        @media (max-width: 1024px) {
          grid-template-columns: repeat(4, 1fr);

          grid-template-areas: 'card-block-1 card-block-1 card-block-2 card-block-2';
        }
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-areas:
            'card-block-2'
            'card-block-1';
        }

        .card-block-1 {
          grid-area: card-block-1;
        }
        .card-block-2 {
          grid-area: card-block-2;
          grid-gap: 20px;
          @media (max-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'block-detail block-awards';
          }

          @media (max-width: 690px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-areas:
              'block-awards'
              'block-detail';
              
            grid-gap:10px;
          }
          .block-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            grid-area: block-detail;
          }
          .block-awards {
            grid-area: block-awards;
            display: flex;
            img {
              margin-left: 20px;
              @media (max-width: 768px) {
                margin-left: 0px;
              }
              @media (max-width: 690px) {
                margin-left: -10%;
              }
              @media (max-width: 475px) {
                margin-left: -20px;
              }
              /* @media (max-width: 320px) {
                margin-left: -35px;
              } */
            }
            margin-bottom: 15px;
            @media (max-width: 768px) {
              margin-bottom: 0px;
            }
            @media (max-width: 690px) {
              margin: auto;
            }
            .image-awards {
              width: 100%;
              max-width: 100px;
              height: 300px;
              @media (max-width: 690px) {
                max-width: 80px;
                height: 230px;
              }
            }
            .image-logo {
              width: 100%;
              max-width: 170px;
              max-height: 50px;
              margin: unset;

              @media (max-width: 690px) {
                max-width: 180px;
                max-height: 100%;
              }
              @media (max-width: 370px) {
                max-width: 150px;
              }
              /* @media (max-width: 320px) {
                max-width: 110px;
              } */
            }

            .time-awards {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              @media (max-width: 768px) {
                padding-left: 15px;
              }
              @media (max-width: 690px) {
                padding-left: 15px;
              }
              @media (max-width: 425px) {
                padding-left: 30px;
                padding-right: 30px;
              }
              @media (max-width: 370px) {
                padding-left: 25px;
                padding-right: 25px;
              }
              .paragraph-1 {
                font-size: 18px;
                font-weight: 700;
                padding-top: 10px;
                @media (max-width: 768px) {
                  font-size: 17px;
                }
                @media (max-width: 370px) {
                  font-size: 15px;
                }
              }
              .time-countdown {
                min-width: 255px;
                grid-template-columns: repeat(2, 1fr);
                display: grid;
                text-align: center;
                line-height: 1;
                column-gap: 15px;
                @media (max-width: 375px) {
                  min-width: 230px;
                }
                @media (max-width: 320px) {
                  min-width: 200px;
                }
                  & > div {
                    padding-bottom: 5px;
                  }
                .paragraph-2 {
                  font-size: 72px;
                  font-weight: 900;
                }
                .paragraph-3 {
                  font-size: 22px;
                  font-weight: 400;
                }
                @media (max-width: 690px) {
                  .paragraph-2 {
                    font-size: 64px;
                  }
                  .paragraph-3 {
                    font-size: 20px;
                    font-weight: 500;
                  }
                }
                @media (max-width: 370px) {
                  .paragraph-2 {
                    font-size: 52px;
                  }
                  .paragraph-3 {
                    font-size: 20px;
                  }
                }
              }
            }
            .image-awards-mobile {
              padding-top: 10px;
              padding-left: 15px;
              display: flex;
              width: 100%;
              justify-content: center;
              @media (max-width: 425px) {
              padding-top: 6px;
              padding-left: 12px;
              margin-left: 5px;
              margin-right: 12px;
            }
              .time-countdown {
                grid-gap: unset;
              }
            }
          }
          .image-awards-mobile {
            max-width: 100px;
            margin-right: 20px;
            @media (max-width: 370px) {
              max-width: 88px;
            }
          }
          .title-awards {
            font-size: 20px;
            font-weight: 700;
          }
          .desc-awards {
            margin: 15px 0 0 0;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    .block-item-2 {
      grid-area: block-item-2;
      /* @media (max-width: 690px) {
        display: none;
      } */
      .card-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin-top: 15px;
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
          .card-item {
            &:not(:nth-child(1), :nth-child(2)) {
              display: none;
            }
          }
        }
      }

      .read-more {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 22px;
        }
        .read-more-text {
          padding: 2px 15px;
          background-color: rgb(220 21 53);
          border-radius: 8px;
          a {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }
`;

export default SectionEvent;
