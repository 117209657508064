/* eslint-disable multiline-ternary */
import _ from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CardH from 'src/components/cards/card-h';
import CardOverlay from 'src/components/cards/card-overlay';
import CardV from 'src/components/cards/card-v';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

function SectionTwoThree({ dataSet2, data2, data3, dataSet3 }: any) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 767px)'
  });
  const router = useRouter();
  return (
    <SectionWrapper className='section-pride-2-3' bgImage={_.find(dataSet2, { key: 'background-image' })?.value || _.find(dataSet3, { key: 'background-image' })?.value}>
      {!_.isEmpty(dataSet2) && !_.isEmpty(data2) ? (
        <>
          <div className='container section-pride-2-dt'>
            <a href={_.find(dataSet2, { key: 'link' })?.value} aria-label={_.find(dataSet2, { key: 'link' })?.value} title={_.find(dataSet2, { key: 'link' })?.value}>
              <img className='title-2' src={_.find(dataSet2, { key: 'title' })?.value} />
            </a>
            {data2.slice(0, 3).map((item: any, index: number) => (
              <CardH
                data={item}
                no={index}
                showTitle={true}
                showBlurb={true}
                showByline={false}
                showTime={false}
                showCountview={false}
                external={false}
                showImage={true}
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_INDEX,
                  router: router?.pathname,
                  section: 'section-pride-2',
                  data: {
                    title: item?.title,
                    heading: item?.subCategory?.en,
                    index: index + 1
                  }
                }}
              />
            ))}
          </div>
          <div className='container section-pride-2-mb'>
            <a href={_.find(dataSet2, { key: 'link' })?.value} aria-label={_.find(dataSet2, { key: 'link' })?.value} title={_.find(dataSet2, { key: 'link' })?.value}>
              <img className='title-2' src={_.find(dataSet2, { key: 'title' })?.value} />
            </a>
            <CardV
              data={data2[0]}
              showTitle={true}
              showBlurb={false}
              showByline={false}
              showTime={false}
              showCountview={false}
              external={false}
              //! DATALAYER
              eventDataLayer={{
                block: '1',
                type: DATALAYER_TYPE.TRACK_INDEX,
                router: router?.pathname,
                section: 'section-pride-2-mb',
                data: {
                  title: data2[0]?.title,
                  heading: data2[0]?.subCategory?.en
                }
              }}
            />
            <div className='container card-wrapper-right'>
              {data2.slice(1, 3).map((item: any, index: number) => (
                <CardOverlay
                  key={index}
                  data={item}
                  showTitle={true}
                  showBlurb={false}
                  showByline={false}
                  showTime={false}
                  external={false}
                  //! DATALAYER
                  eventDataLayer={{
                    block: '2',
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: 'section-pride-2-mb',
                    data: {
                      title: item?.title,
                      heading: item?.subCategory?.en,
                      index: index + 1
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
      {!_.isEmpty(dataSet3) && !_.isEmpty(data3) ? (
        <div className='container section-pride-3'>
          {/* <a href={_.find(dataSet3, { key: 'link' })?.value} aria-label={_.find(dataSet3, { key: 'link' })?.value} title={_.find(dataSet3, { key: 'link' })?.value}> */}
          <img className='title-3' src={_.find(dataSet3, { key: 'title' })?.value} />
          {/* </a> */}
          <div className='card-wrapper'>
            <a className='view-more' href={_.find(dataSet3, { key: 'link' })?.value} aria-label={_.find(dataSet3, { key: 'link' })?.value} title={_.find(dataSet3, { key: 'link' })?.value}>
              <p>ดูทั้งหมด</p>
            </a>
            {data3.slice(0, isDesktopOrLaptop ? 6 : 4).map((item: any, index: number) => (
              <CardV
                key={index}
                data={item}
                showTitle={true}
                showBlurb={false}
                showByline={false}
                showTime={false}
                showCountview={false}
                external={false}
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_INDEX,
                  router: router?.pathname,
                  section: 'section-pride-3',
                  data: {
                    title: item?.title,
                    heading: item?.subCategory?.en,
                    index: index + 1
                  }
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
    </SectionWrapper>
  );
}
interface IStyle {
  bgImage?: string;
}
const SectionWrapper = styled.div<IStyle>`
  position: relative;
  width: 100%;
  min-height: 800px;
  height: auto;
  padding: 0 15px;
  padding-bottom: 50px;
  padding-top: 60px;
  background-image: ${props => `url(${props.bgImage})`};
  margin-top: 50px;
  background-size: cover;

  .section-background {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .title-2 {
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 45%;
    @media (max-width: 992px) {
      top: -100px;
      width: 65%;
    }
    @media (max-width: 767px) {
      top: -90px;
    }
    @media (max-width: 767px) {
      top: -80px;
      width: 80%;
    }
  }
  .section-pride-2-dt {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 10px;
    margin-bottom: 70px;
    @media (max-width: 767px) {
      display: none;
    }
    .card-h {
      width: 80%;
      /* background-color: #fff; */
      background: #ffffff;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: inset 4px 0px 5px rgba(204, 204, 203, 0.75), inset -4px 0px 5px rgba(204, 204, 203, 0.75), inset 0px -4px 5px rgba(204, 204, 203, 0.75), inset 0px 4px 5px rgba(204, 204, 203, 0.75);
      @media (max-width: 767px) {
        width: 100%;
      }
      .row {
        justify-content: flex-start;
        .img-scale-animate {
          .resolution-image {
            height: 100%;
          }
          padding: 0;
        }
        .card {
          width: 58%;
          padding: 40px 10px 20px 10px;
          @media (max-width: 992px) {
            padding: 20px 10px;
          }
          .title {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: 600;
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
          p {
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .section-pride-2-mb {
    align-items: center;
    width: 100%;
    position: relative;
    display: none;
    gap: 5px;
    @media (max-width: 767px) {
      display: flex;
    }
    .card-v {
      width: 60%;
      background-color: #fff;
      box-shadow: inset 4px 0px 5px rgba(204, 204, 203, 0.75), inset -4px 0px 5px rgba(204, 204, 203, 0.75), inset 0px -4px 5px rgba(204, 204, 203, 0.75), inset 0px 4px 5px rgba(204, 204, 203, 0.75);
      border-radius: 15px;
      @media (max-width: 475px) {
        width: 50%;
      }
      .--content {
        padding: 0 5px 5px 5px;
      }
    }
    .card-wrapper-right {
      width: 40%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0 5px;

      @media (max-width: 475px) {
        width: 50%;
        padding: 0;
      }
      .card-overlay {
        width: 100%;
        margin: 0;
        .title {
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
    }
  }
  .section-pride-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 30px;
    margin: 30px auto;
    .title-3 {
      width: 80%;
      @media (max-width: 767px) {
        width: 90%;
      }
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      background-color: #fff;
      border-radius: 20px;
      padding: 20px 0;
      gap: 20px 0;
      background: #ffffff;
      box-shadow: inset 4px 0px 5px rgba(204, 204, 203, 0.75), inset -4px 0px 5px rgba(204, 204, 203, 0.75), inset 0px -4px 5px rgba(204, 204, 203, 0.75), inset 0px 4px 5px rgba(204, 204, 203, 0.75);
      .card-v {
        width: 32%;
        padding: 0 5px;
        @media (max-width: 767px) {
          width: 48%;
        }
      }
      .view-more {
        cursor: pointer;
        p {
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translate(-50%, 0);
          padding: 2px 20px;
          background-color: rgb(220 21 53);
          color: #fff;
          border-radius: 8px;
          &:hover {
            color: #ed1a3b;
            background-color: rgb(255, 255, 255);
          }
        }
      }
    }
  }
`;
export default SectionTwoThree;
