import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import CardOverlay from 'src/components/cards/card-overlay';
import { NextPage } from 'next';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';

type Props = {
  slides: any[];
  autoplay?: boolean;
  interval?: number;
  arrows?: boolean;
  arrowBorders?: boolean;
  eventDataLayer?: IDataLayerGlobal;
};

const Carousel3d: NextPage<Props> = props => {
  //   const { eventDataLayer } = props;
  const [slideTotal, setSlideTotal] = useState(0);
  const [slideCurrent, setSlideCurrent] = useState(-1);
  const [slides, setSlides] = useState([]);
  // const [height, setHeight] = useState('0px');
  const router = useRouter();
  const intervalRef: any = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => slideRight(),
    onSwipedRight: () => slideLeft(),
    // preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  useEffect(() => {
    const locSlides: any = [];
    // console.log(props.slides);
    props.slides.forEach((slide: any) => {
      const slideobject = {
        class: 'slider-single proactivede',
        element: slide
      };
      locSlides.push(slideobject);
    });
    if (props.slides.length === 2) {
      props.slides.forEach((slide: any) => {
        const slideobject = {
          class: 'slider-single proactivede',
          element: slide
        };
        locSlides.push(slideobject);
      });
    }
    setSlides(locSlides);
    setSlideTotal(locSlides.length - 1);
    setSlideCurrent(-1);
    // console.log(slideCurrent);
    if (slideCurrent === -1) {
      setTimeout(() => {
        slideRight();
        if (props.autoplay) {
          intervalRef.interval = setTimeout(() => {
            slideRight();
          }, props.interval);
        }
      }, 500);
    }
  }, [props.slides]);
  useEffect(() => {
    if (slideCurrent === -1) {
      setTimeout(() => {
        slideRight();
      }, 500);
    }
  }, [slides, slideCurrent]);

  const slideRight = () => {
    let preactiveSlide: any;
    let proactiveSlide: any;
    let slideCurrentLoc = slideCurrent;

    const activeClass = 'slider-single active';
    const slide: any = [...slides];
    if (slideTotal > 1) {
      if (slideCurrentLoc < slideTotal) {
        slideCurrentLoc++;
      } else {
        slideCurrentLoc = 0;
      }
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      const activeSlide: any = slide[slideCurrentLoc];
      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }

      slide.forEach((slid: any) => {
        if (slid.class.includes('preactivede')) {
          slid.class = 'slider-single proactivede';
        }
        if (slid.class.includes('preactive')) {
          slid.class = 'slider-single preactivede';
        }
      });

      preactiveSlide.class = 'slider-single preactive';
      activeSlide.class = activeClass;
      proactiveSlide.class = 'slider-single proactive';
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);

      // if (document.getElementsByClassName('slider-single active').length > 0) {
      //   setTimeout(() => {
      //     if (document.getElementsByClassName('slider-single active').length > 0) {
      //       const height = document.getElementsByClassName('slider-single active')[0].clientHeight;
      //       setHeight(`${height}px`);
      //     }
      //   }, 0);
      // }
      if (props.autoplay) {
        clearTimeout(intervalRef.interval);
        intervalRef.interval = setTimeout(() => {
          slideRight();
        }, props.interval);
      }
    } else if (slide[0] && slide[0].class !== activeClass) {
      slide[0].class = activeClass;
      setSlides(slide);
      setSlideCurrent(0);
    }
  };
  const slideLeft = () => {
    if (slideTotal > 1) {
      let preactiveSlide: any;
      let proactiveSlide: any;
      let slideCurrentLoc = slideCurrent;
      const slide = [...slides];
      if (slideCurrentLoc > 0) {
        slideCurrentLoc--;
      } else {
        slideCurrentLoc = slideTotal;
      }

      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }
      const activeSlide: any = slide[slideCurrentLoc];
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      slide.forEach((slid: any) => {
        if (slid.class.includes('proactivede')) {
          slid.class = 'slider-single preactivede';
        }
        if (slid.class.includes('proactive')) {
          slid.class = 'slider-single proactivede';
        }
      });
      preactiveSlide.class = 'slider-single preactive';
      activeSlide.class = 'slider-single active';
      proactiveSlide.class = 'slider-single proactive';
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);
      // if (document.getElementsByClassName('slider-single active').length > 0) {
      //   setTimeout(() => {
      //     if (document.getElementsByClassName('slider-single active').length > 0) {
      //       const height = document.getElementsByClassName('slider-single active')[0].clientHeight;
      //       setHeight(`${height}px`);
      //     }
      //   }, 0);
      // }
    }
  };

  const sliderClass = (direction: any) => {
    let sliderClass = `slider-${direction}`;
    if (!props.arrows) {
      sliderClass = 'slider-disabled';
    } else if (props.arrows && !props.arrowBorders) {
      sliderClass = `slider-${direction}-noborders`;
    }
    return sliderClass;
  };

  return (
    <SectionWrapper className='react-3d-carousel' {...handlers}>
      {slides && slides.length > 0 && (
        <div className='slider-container'>
          <div className='slider-content'>
            {slides.map((slider: any, index) => (
              <div className={slider.class} key={index}>
                {slides.length > 1 && (
                  <div className={sliderClass('left')} onClick={slideLeft}>
                    <div>
                      <BsArrowLeftCircleFill />
                    </div>
                  </div>
                )}

                {slides.length > 1 && (
                  <div className={sliderClass('right')} onClick={slideRight}>
                    <div>
                      <BsArrowRightCircleFill />
                    </div>
                  </div>
                )}

                <div className='slider-single-content'>
                  <CardOverlay
                    data={slider.element}
                    showTitle={true}
                    showBlurb={false}
                    showByline={false}
                    showTime={false}
                    external={false}
                    showBlurbTop={false}
                    // ! DATALAYER
                    // eventDataLayer={{}}
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'section-pride-1',
                      data: {
                        block: '1',
                        title: slider.element?.title,
                        heading: slider.element?.subCategory?.en,
                        index: index + 1
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  min-height: 480px;
  /* padding-top: 50px; */
  @media (max-width: 1024px) {
    min-height: 352px;
  }
  @media (max-width: 768px) {
    min-height: 300px;
  }
  @media (max-width: 475px) {
    min-height: 193px;
  }
  &.react-3d-carousel {
    position: relative;
    // height: 600px;
    .slider-container {
      position: absolute;
      left: 50%;
      top: 0%;
      // bottom:-50%;
      width: 90%;
      // height: 50vw;
      margin: -0px auto 0px -45%;
      .slider-content {
        position: relative;
        left: 50%;
        top: 0%;
        width: 70%;
        height: 80%;
        transform: translateX(-50%);
        @media (max-width: 690px) {
          width: 90%;
        }
        .slider-single {
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          width: 100%;
          // height: 100%;
          /* transition: z-index 0ms 250ms; */
          .slider-single-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            //   height: 100%;
            transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
            transform: scale(0);
            opacity: 0;

            /* .img-scale-animate {
              @media (max-width: 690px) {
                padding-top: 82%;
              }
            } */
            .slider-img {
              display: flex;
              justify-content: center;
            }
            img {
              box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
              border-radius: 15px;
              max-width: 100%;
              /* width: 240px; */
              -moz-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -webkit-user-drag: none;
              user-drag: none;
              -webkit-touch-callout: none;
              @media (max-width: 480px) {
                max-width: 100%;
              }
            }
          }
          &.preactivede {
            //z-index: 0;
            .slider-single-content {
              //opacity: 0;
              transform: translateX(-50%) scale(0);
            }
          }
          &.preactive,
          &.proactive {
            .slider-single-content {
              /* opacity: 0.3; */
              .blurb-on-top {
                display: none;
              }
              .img-scale-animate {
                &::before {
                  content: '';
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background-color: #000;
                  opacity: 0.5;
                }
              }
              opacity: 1;
            }
          }
          &.preactive {
            z-index: 1;
            .slider-single-content {
              transform: translateX(-36%) scale(0.8);
              @media (max-width: 690px) {
                transform: translateX(-20%) scale(0.8);
              }
            }
          }
          &.proactive {
            z-index: 1;
            .slider-single-content {
              transform: translateX(36%) scale(0.8);
              @media (max-width: 690px) {
                transform: translateX(20%) scale(0.8);
              }
            }
          }
          &.proactivede {
            //z-index: 0;
            .slider-single-content {
              //opacity: 0;
              transform: translateX(50%) scale(0);
            }
          }
          &.active {
            /* position: relative; */
            .slider-left,
            .slider-right {
              display: block;
              svg {
                filter: drop-shadow(0 1px 2px rgb(255, 255, 255));
              }
              @media (max-width: 690px) {
                display: none;
              }
            }

            z-index: 111111;
            .slider-single-content {
              opacity: 1;
              transform: translateX(0%) scale(1);
            }
          }
        }
      }
      .slider-left {
        position: absolute;
        display: none;
        z-index: 3;
        right: 101%;
        // margin-right: 5px;
        padding: 20px 15px;
        height: 100%;
        cursor: pointer;
        div {
          position: absolute;
          top: 50%;
          left: 0%;
          margin-top: -20px;
          // color: #ffffff;
          transform: translateY(-50%);
          svg {
            font-size: 26px;
          }
          // padding: 10px 10px;
          // border-top: 2px solid #fdc84b;
          // border-right: 2px solid #fdc84b;
          // border-bottom: 2px solid #fdc84b;
          // border-left: 2px solid #fdc84b;
        }
      }
      .slider-right {
        display: none;
        position: absolute;
        z-index: 3;
        left: 101%;
        padding: 20px 15px;
        height: 101%;
        cursor: pointer;
        div {
          position: absolute;
          top: 50%;
          left: 0%;
          margin-top: -20px;
          // color: #ffffff;
          transform: translateY(-50%);
          // padding: 10px 10px;
          // border-top: 2px solid #fdc84b;
          // border-right: 2px solid #fdc84b;
          // border-bottom: 2px solid #fdc84b;
          // border-left: 2px solid #fdc84b;
          svg {
            font-size: 26px;
          }
        }
        margin-left: -2px;
      }
      .slider-left-noborders {
        position: absolute;
        display: none;
        z-index: 3;
        right: 101%;
        margin-right: 5px;
        padding: 20px 15px;
        height: 100%;
        cursor: pointer;
        div {
          position: absolute;
          top: 50%;
          left: 0%;
          margin-top: -20px;
          color: #ffffff;
          transform: translateY(-50%);
          padding: 10px 10px;
        }
      }
      .slider-right-noborders {
        display: none;
        position: absolute;
        z-index: 3;
        left: 101%;
        padding: 20px 15px;
        height: 101%;
        cursor: pointer;
        div {
          position: absolute;
          top: 50%;
          left: 0%;
          margin-top: -20px;
          color: #ffffff;
          transform: translateY(-50%);
          padding: 10px 10px;
        }
        margin-left: -2px;
      }
      .slider-disabled {
        div {
          display: none;
        }
      }
    }
  }
`;

export default Carousel3d;

Carousel3d.defaultProps = {
  autoplay: false,
  interval: 3000,
  arrows: true,
  arrowBorders: true
};
