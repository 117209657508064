export function convertDatasets(data: any, topic: string, isValue: boolean) {
  const results = [];
  if (isValue) {
    for (let i = 0; i < data?.length; i++) {
      if (data[i].key.startsWith(topic)) {
        results.push(data[i].value);
      }
    }
  } else {
    for (let i = 0; i < data?.length; i++) {
      if (data[i].topic.startsWith(topic)) {
        results.push(data[i].dataSet);
      }
    }
  }
  return isValue ? results[0] : results;
}

export const generateDataFromDataset = (_data: any) => {
  const resArray: any = [];
  for (let index = 0; index < 4; index++) {
    const resObj: any = {};
    resObj.imageUrl = convertDatasets(_data, `image-${index + 1}`, true);
    resObj.fullName = convertDatasets(_data, `name-${index + 1}`, true);
    resObj.position = convertDatasets(_data, `position-${index + 1}`, true);
    resArray.push(resObj);
  }
  return resArray;
};
export function convertDataLists(data:any[], keys: any[]) {
  const result:any = {};

  keys.forEach(key => {
      const keyData = data.filter(item => item.key.startsWith(key));
      const values = keyData.map(item => item.value);
      result[key.substring(0, key.length - 1)] = values;
  });

  return result;
}

export function ModifyMenu(_array: any[]) {
  if (!Array.isArray(_array) || !_array) return [];
  return (
    Array.isArray(_array) &&
    _array.reduce((acc, cur) => {
      const indexEl = _array.findIndex(el => el.nameEng.indexOf('lottery') && cur.nameEng.indexOf('lottery'));
      if (indexEl !== -1) {
        return [...acc, { ...cur }];
      } else {
        return [...acc, { ...cur, sub: [] }];
      }
    }, [])
  );
}
export function convertDatasetsLists(data: any[], topic: string) {
  console.log(data, Object.keys(data));
  
  const results: any[] = [];
  data.forEach(el => {
    const indexEl = topic.indexOf(el.key);
    if (indexEl !== -1) {
      return results.push(el.value);
    }
  }, []);

  return results;
}
