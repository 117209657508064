import _ from 'lodash';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { GET } from 'src/services';
import { convertObjPath, timestamp } from 'src/utils/helper';
import { convertDatasets } from 'src/utils/datasets';
import { useRouter } from 'next/router';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
const CardV = dynamic(import('src/components/cards/card-v'));
const Image = dynamic(import('src/components/cards/image'));
type Props = {
  data?: any;
};
const SectionElection: NextPage<Props> = ({ data }) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();
  const allData = {
    item1: convertDatasets(data, 'image-1', true),
    item2: convertDatasets(data, 'image-2', true),
    item3: convertDatasets(data, 'switch-key', true),
    item4: convertDatasets(data, 'section-2', true),
    item5: convertDatasets(data, 'section-3', true),
    item6: convertDatasets(data, 'section-4', true),
    item7: convertDatasets(data, 'cross', true),
    item8: convertDatasets(data, 'description', true),
    item9: convertDatasets(data, 'sub-category', true),
    item10: convertDatasets(data, 'category', true),
    item11: convertDatasets(data, 'election-day', true),
    item12: convertDatasets(data, 'title-section-2', true),
    item13: convertDatasets(data, 'title-section-3', true),
    item14: convertDatasets(data, 'title-section-4', true)
  };
  const [resDataSection1, setResDataSection1] = useState<any>();
  const [resDataSection2, setResDataSection2] = useState<any>();
  const [resDataSection3, setResDataSection3] = useState<any>();
  const [resDataSection4, setResDataSection4] = useState<any>();
  const [key, setKet] = useState<any>('');
  const [remainingDay, setRemainingDay] = useState<any>(0);

  useEffect(() => {
    const future: any = Date.parse(!_.isEmpty(allData?.item11) ? allData.item11 : new Date());
    const now: any = new Date();
    const remainingTime: any = future - now;
    const remaining = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    setRemainingDay(remaining < 1 ? '' : remaining);
    (async () => {
      try {
        if (!_.isEmpty(allData)) {
          if (allData.item3 === 'tag' || allData.item3 === 'tags') {
            setKet('tag');
            const res: any = await Promise.all([
              GET(`/api/search/tag${convertObjPath({ name: allData.item4 })}&limit=6`, false, true),
              GET(`/api/search/tag${convertObjPath({ name: allData.item5 })}&limit=6`, false, true),
              GET(`/api/search/tag${convertObjPath({ name: allData.item6 })}&limit=6`, false, true)
            ]);
            if (!_.isEmpty(res[0])) setResDataSection2(_.uniqBy(res[0]?.data, 'id'));
            if (!_.isEmpty(res[1])) setResDataSection3(_.uniqBy(res[1]?.data, 'id'));
            if (!_.isEmpty(res[2])) setResDataSection4(_.uniqBy(res[2]?.data, 'id'));
          } else if (allData.item3 === 'sub-category' || allData.item3 === 'sub-cate') {
            setKet('sub-category');
            const res: any = await Promise.all([
              GET(`/api/v1.0/categories/politics/${allData.item4}`, false, true),
              GET(`/api/v1.0/categories/politics/${allData.item5}`, false, true),
              GET(`/api/v1.0/categories/politics/${allData.item6}`, false, true)
            ]);

            if (!_.isEmpty(res[0])) setResDataSection2(_.uniqBy(res[0]?.data, 'id'));
            if (!_.isEmpty(res[1])) setResDataSection3(_.uniqBy(res[1]?.data, 'id'));
            if (!_.isEmpty(res[2])) setResDataSection4(_.uniqBy(res[2]?.data, 'id'));
          }
          const resData = await GET(`/api/v1.0/categories/${allData.item10}${_.isEmpty(allData.item9) ? '' : `/${allData.item9}`}`, false, true);

          if (!_.isEmpty(resData)) setResDataSection1(_.uniqBy(resData?.data, 'id'));
        }
      } catch (err: any) {
        console.error(`${timestamp()} ==========> EVENT_ELECTION-2566 ERROR :`, err?.message);
      }
    })();
  }, []);
  return (
    <SectionWrapper id='section-election2566'>
      <div className='election-section1'>
        <div className='icon-header'>
          {!_.isEmpty(allData?.item1) && (
            <div className='icon-top'>
              <Image image={allData?.item1} title={'icon-top'} external={true} />
            </div>
          )}
          {!_.isEmpty(allData?.item2) && (
            <div className='icon-bottom'>
              <Image image={allData?.item2} title={'icon-bottom'} external={true} />
            </div>
          )}
          <div className='countdown-time'>
            <span>{remainingDay}</span>
          </div>
        </div>
        <div className='content'>
          {!_.isEmpty(allData?.item8) && <div className='description'>{allData.item8}</div>}
          {!_.isEmpty(allData?.item10) && (
            <div className='headline'>
              <div className='text'>
                <a href={`/category/${allData.item10}`}>{allData.item10}</a>
                <span>●</span>
                {!_.isEmpty(allData?.item9) && <a href={`/category/${allData.item10}/${allData.item9}`}>{allData.item9}</a>}
              </div>

              <div className='line'></div>
              <div className='view-all'>
                <a href={_.isEmpty(allData?.item9) ? `/category/${allData.item10}` : `/category/${allData.item10}/${allData.item9}`}>ดูทั้งหมด</a>
              </div>
            </div>
          )}
          {!_.isEmpty(resDataSection1) && (
            <div className='list-item'>
              {resDataSection1?.slice(0, 3).map((item: any, index: any) => (
                <div className={'card-item'}>
                  <CardV
                    data={item}
                    showTitle={false}
                    showBlurb={false}
                    showByline={false}
                    showTime={false}
                    showCountview={false}
                    external={false}
                    //! DATALAYER
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'section-election1',
                      data: {
                        title: item?.title,
                        heading: 'section-election1',
                        index: index + 1
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {!_.isEmpty(resDataSection2) && (
        <div className='election-section2'>
          <div className='headline'>
            <div className='cross'>
              <Image image={allData?.item7} title={'icon-top'} external={true} />
            </div>
            <div className='line'></div>
            <div className='title'>{allData.item12}</div>
            <div className='line'></div>
            <div className='view-all'>
              <a href={key === 'tag' ? `tags/${allData.item4}` : `category/politics/${allData.item4}`}>ดูทั้งหมด</a>
            </div>
          </div>
          <div className='card-list'>
            {resDataSection2?.slice(0, 3).map((item: any, index: any) => (
              <div className={'card-item'}>
                <CardV
                  data={item}
                  showTitle={true}
                  showBlurb={false}
                  showByline={false}
                  showTime={false}
                  showCountview={false}
                  external={false}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: 'section-election2',
                    data: {
                      title: item?.title,
                      heading: 'section-election2',
                      index: index + 1
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* ====================== boonmee lab ======================= */}

      {!_.isEmpty(resDataSection3) && (
        <div className='election-section3'>
          <div className='headline'>
            <div className='cross'>
              <Image image={allData?.item7} title={'icon-top'} external={true} />
            </div>
            <div className='line'></div>
            <div className='title'>{allData.item13}</div>
            <div className='line'></div>
            <div className='view-all'>
              <a href={key === 'tag' ? `tags/${allData.item5}` : `category/politics/${allData.item5}`}>ดูทั้งหมด</a>
            </div>
          </div>
          <div className='card-list'>
            {resDataSection3?.slice(0, 3).map((item: any, index: any) => (
              <div className={'card-item'}>
                <CardV
                  data={item}
                  showTitle={true}
                  showBlurb={false}
                  showByline={false}
                  showTime={false}
                  showCountview={false}
                  external={false}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: 'section-election3',
                    data: {
                      title: item?.title,
                      heading: 'section-election3',
                      index: index + 1
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!_.isEmpty(resDataSection4) && (
        <div className='election-section4'>
          <div className='headline'>
            <div className='cross'>
              <Image image={allData?.item7} title={'icon-top'} external={true} />
            </div>
            <div className='line'></div>
            <div className='title'>{allData.item14}</div>
            <div className='line'></div>
            <div className='view-all'>
              <a href={key === 'tag' ? `tags/${allData.item6}` : `category/politics/${allData.item6}`}>ดูทั้งหมด</a>
            </div>
          </div>
          <div className='card-list'>
            {resDataSection4?.slice(0, 3).map((item: any, index: any) => (
              <div className={'card-item'}>
                <CardV
                  data={item}
                  showTitle={true}
                  showBlurb={false}
                  showByline={false}
                  showTime={false}
                  showCountview={false}
                  external={false}
                  //! DATALAYER
                  eventDataLayer={{
                    type: DATALAYER_TYPE.TRACK_INDEX,
                    router: router?.pathname,
                    section: 'section-election4',
                    data: {
                      title: item?.title,
                      heading: 'section-election4',
                      index: index + 1
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </SectionWrapper>
  );
};
type PropStyled = {
  background?: string;
};
const SectionWrapper = styled.section<PropStyled>`
  .election-section1 {
    display: flex;
    column-gap: 20px;
    align-items: flex-end;
    @media (max-width: 690px) {
      flex-direction: column;
    }
    .icon-header {
      position: relative;
      width: 30%;
      @media (max-width: 690px) {
        display: flex;
        width: 100%;
      }
      .resolution-image {
        padding-top: 0 !important;
      }
      img {
        position: unset;
      }
      .icon-top {
        padding: 0 46px;
        @media (max-width: 1100px) {
          padding: 0 20px;
        }
        @media (max-width: 768px) {
          padding: 0 0px;
        }
        @media (max-width: 690px) {
          width: 45%;
        }
      }
      .icon-bottom {
        @media (max-width: 690px) {
          position: relative;
          width: 55%;
        }
      }
      .countdown-time {
        position: absolute;
        font-size: 150px;
        font-weight: 700;
        color: #ea1b3c;
        top: 66%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 1100px) {
          font-size: 120px;
          top: 68%;
        }
        @media (max-width: 768px) {
          font-size: 100px;
          top: 70%;
        }
        @media (max-width: 690px) {
          top: 10%;
          left: 60%;
          transform: translate(5%, -0%);
        }
        @media (max-width: 425px) {
          font-size: 80px;
          top: 3%;
          left: 58%;
          transform: translate(5%, -0%);
        }
        @media (max-width: 375px) {
          font-size: 70px;
        }
        @media (max-width: 320px) {
          font-size: 60px;
        }
      }
    }
    .content {
      width: 70%;
      @media (max-width: 690px) {
        width: 100%;
      }
      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.8;
        background-color: #cdcccc;
        color: #231f20;
        font-weight: 300;
        font-size: 18px;
        padding: 6px 12px;
        @media (max-width: 425px) {
          font-size: 14px;
        }
      }
      .headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        @media (max-width: 690px) {
          padding-top: 16px;
        }
        .text {
          display: flex;
          align-items: center;
        }
        a {
          text-transform: uppercase;
          font-size: 26px;
          font-weight: 300;
          @media (max-width: 425px) {
            font-size: 22px;
          }
        }
        span {
          padding: 0 8px;
          color: #ea1b3c;
        }
        .line {
          margin-left: 40px;
          width: 100%;
          padding: 1px;
          background-color: #cdcccc;
          height: 1px;
          @media (max-width: 425px) {
            display: none;
          }
        }
      }
      .list-item {
        display: flex;
        justify-content: center;
        .card-item {
          width: 33%;
          .--content {
            margin-top: 0;
          }
          .resolution-image {
            padding-top: 180% !important;
            display: flex;
          }
        }
      }
    }
  }
  .election-section2,
  .election-section3,
  .election-section4 {
    padding-top: 10px;
    .headline {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (max-width: 375px) {
        justify-content: space-between;
      }
      .cross {
        position: absolute;
        width: 40px;
        left: 0%;
        @media (max-width: 690px) {
          width: 32px;
        }
        @media (max-width: 425px) {
          width: 24px;
        }
        @media (max-width: 375px) {
          display: none;
        }
        img {
          position: unset;
          display: block;
        }
        .resolution-image {
          padding-top: 0 !important;
          display: flex;
        }
      }
      .line {
        width: 100%;
        padding: 1.5px;
        background-color: #ea1b3c;
        height: 2px;
        @media (max-width: 375px) {
          display: none;
        }
        &:nth-child(2) {
          margin-left: 40px;
          @media (max-width: 690px) {
            margin-left: 32px;
          }
          @media (max-width: 425px) {
            margin-left: 22px;
          }
        }
      }
      .title {
        margin: 0 20px;
        font-size: 60px;
        font-weight: 700;
        white-space: nowrap;
        @media (max-width: 768px) {
          font-size: 36px;
        }
        @media (max-width: 550px) {
          font-size: 24px;
        }
        @media (max-width: 375px) {
          margin: 0;
        }
      }
    }
    .card-list {
      display: flex;
      column-gap: 20px;
      padding-bottom: 10px;
      .card-item {
        width: 33.33%;
        @media (max-width: 425px) {
          width: 50%;
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  .view-all {
    background-color: #ea1b3c;
    border-radius: 15px;
    padding: 2px 12px;
    @media (max-width: 425px) {
      padding: 0px 10px;
    }
    a {
      color: #fff;
      white-space: nowrap;
      font-size: 16px !important;
      @media (max-width: 425px) {
        line-height: 1;
        font-size: 10px !important;
      }
    }
  }
`;
export default SectionElection;
