import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import styled from 'styled-components';

import { AiOutlineEye } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';

import { useDateUpdate } from 'src/utils/helper';

import { useDesktop } from 'src/utils/device';

import { Related } from 'src/modules/cid/id';
import { Datum } from 'src/modules/sections/section-1';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Image = dynamic(import('src/components/image'));
const WidgetPlayBtn = dynamic(import('src/components/widgets/widget-play-btn'));

type Props = {
  data: Datum | Related | any;
  showTitle?: boolean;
  showBlurb?: boolean;
  showByline?: boolean;
  showTime?: boolean;
  showCountview?: boolean;
  external?: boolean;
  eventDataLayer?: any;
  className?: any;
};

const CardV = ({
  data,
  showTitle = true,
  showBlurb = true,
  showByline = true,
  showTime = true,
  showCountview = true,
  external,
  eventDataLayer = {},
  className = ''
}: // mouseOverHandler = () => {},
  // onMouseOut
  Props) => {
  if (_.isEmpty(data)) return null;
  const [videoEmbed, setVideoEmbed] = useState('');
  const [videoCheck, setVideoCheck] = useState(false);

  const MouseOverHandler = (): any => {
    if (data?.is_video && data?.type_video) {
      if (data?.type_video !== '5') {
        setVideoEmbed(`<iframe
          className='video-hover'
          width='100%'
          height='100%'
          aria-label=${data?.title}
          title=${data?.title}
          src=${data?.video_src}?autoplay=1&mute=1&controls=1&modestbranding=1&showinfo=0
          frameBorder='0'
          allow="accelerometer; autoplay *; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>`);
        setVideoCheck(true);
      }
    }
  };
  const MouseOutHandler = () => {
    if (data?.is_video && data?.type_video !== '5') {
      setVideoEmbed('');
      setVideoCheck(false);
    }
  };

  return (
    <>
      {!_.isEmpty(data) && (
        <CardWrapper className={`${videoCheck ? 'card-youtube-scale' : ''} card-v`}>
          <a
            href={data?.link}
            tabIndex={-1}
            // title={data?.title}
            //! DATALAYER
            onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
          >
            {/* desktop */}
            {useDesktop() && (
              <div className='img-scale-animate next-img-scale' onMouseOver={() => MouseOverHandler()} onClick={() => MouseOverHandler()}>
                <Image image={data?.image} title={data?.title} external={external} />
                {data?.is_video && (
                  <div id='youtubeHoverinVideo' className={`${videoCheck ? 'youtube-hover' : ''}`} onMouseOut={() => MouseOutHandler()} dangerouslySetInnerHTML={{ __html: videoEmbed }}></div>
                )}

                {data?.is_video && <WidgetPlayBtn />}
              </div>
            )}
            {/* mobile */}
            {!useDesktop() && (
              <div className='img-scale-animate next-img-scale'>
                <Image image={data?.image} title={data?.title} external={external} />
                {data?.is_video && <WidgetPlayBtn />}
              </div>
            )}
            <div className='card --content'>
              {showTitle && <h3 className='title text-excerpt-3'>{data?.title}</h3>}
              {showBlurb && <p className='text-excerpt-2'>{data?.blurb}</p>}
              <div className={`${className} meta-contents`}>
                {showByline && !_.isEmpty(data?.byline) && (
                  <div className='byline'>
                    <FaUser /> <span>{data?.byline}</span>
                  </div>
                )}
                <div className='meta'>
                  {showTime && (
                    <p className='date-time'>
                      <BiCalendar />
                      <span> {useDateUpdate(data?.published_at, false)} </span>
                    </p>
                  )}
                  {showCountview && (
                    <>
                      <p className='pageviews'>
                        <AiOutlineEye />
                        <span>{data.pageviews}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </a>
        </CardWrapper>
      )}
    </>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  &.card-youtube-scale {
    z-index: 20;
    background: #1a1a1a;
    padding: 10px;
    &:hover {
      transform: scale(1.3);
    }
    .youtube-hover {
      position: absolute;
      top: -0px;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
  }
  &.card-v {
    //margin-top: 22px;
    /* .next-img-scale {
      position: relative;
      padding-top: 56.25% !important;
      overflow: hidden;
      display: block;
    } */
    .img-scale-animate {
      position: relative;

      .play-btn {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        display: block;
        z-index: 0;
        transition: all 0.5s ease-out;

        svg {
          color: rgba(255, 230, 0);
          font-size: 16px;
          margin: 0 0 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &::after {
          content: '';
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          box-shadow: #9e9e9e 0px 0px 5px;
          width: 45px;
          height: 45px;
          border-radius: 50px;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          left: 50%;
          top: 45%;
          right: 0;
          z-index: -1;
        }
        &:hover {
          svg {
            color: rgba(0, 0, 0, 0.8);
          }
          &::after {
            background: rgba(255, 230, 0.5);
          }
        }
      }
    }
  }
  &:hover {
    h3,
    p {
      color: rgb(220 21 53) !important;
    }
  }
  .card {
    margin-top: 16px;
    h3,
    p {
      transition: all 0.4s ease-in-out;
      color: black;
    }
    &.--content {
      padding: 0;
      width: 100%;
      max-width: 100%;
      .text-excerpt-2 {
        font-size: 14px;
        font-weight: 300;
        padding-top: 8px;
        line-height: 1.65;
      }
      .title {
        /* color: #000; */
      }
      h3 {
        font-size: 18px;
        font-weight: 400;
        padding: 0 2px;
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
    }
  }

  .meta-contents {
    margin-top: 5px;
    .byline {
      color: #808080;
      font-size: 12px;
    }
    .meta {
      margin-top: 5px;
      display: flex;
      .date-time {
        font-size: 12px;
        color: #808080;
      }
      .pageviews {
        margin-left: 10px;
        font-size: 12px;
        color: #808080;
        svg {
          vertical-align: middle;
        }
        span {
          padding-left: 5px;
        }
      }
    }
  }
  .meta-contents-member {
    padding-bottom: 2.5rem;
  }
`;

export default CardV;
