import _ from 'lodash';
import styled from 'styled-components';

import { AiOutlineEye } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';

import { useDateUpdate } from 'src/utils/helper';

import dynamic from 'next/dynamic';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Image = dynamic(import('src/components/image'));
const WidgetPlayBtn = dynamic(import('src/components/widgets/widget-play-btn'));

type Props = {
  data?: any;
  no: number | any;
  showTitle: boolean;
  showBlurb: boolean;
  showByline: boolean;
  showTime: boolean;
  showCountview: boolean;
  external: boolean;
  showImage: boolean;
  eventDataLayer: any;
};

const CardH = ({ data, no = 0, showTitle = true, showBlurb = true, showByline = true, showTime = true, showCountview = true, external, showImage = true, eventDataLayer = {} }: Props) => {
  if (_.isEmpty(data)) return null;
  return (
    <>
      <CardWrapper className='card-h'>
        <a
          href={data?.link}
          tabIndex={-1}
          // title={data?.title}
          //! DATALAYER
          onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
        >
          <div className={`${showImage ? 'row' : 'card-h-most-view'}`}>
            <div className={`img-scale-animate mb-2 ${showImage ? 'col-5' : ''}`}>
              {showImage && <Image image={data?.image} title={data?.title} external={external} />}
              {!showImage && <h3>{no + 1}</h3>}
              {showImage && data?.is_video && <WidgetPlayBtn />}
            </div>
            <div className={`card --content ${showImage ? 'col-7' : ''}`}>
              {showTitle && <h3 className='title text-excerpt-3'>{data?.title}</h3>}
              {showBlurb && <p className='text-excerpt-2'>{data?.blurb}</p>}
              <div className='meta-contents'>
                {showByline && !_.isEmpty(data?.byline) && (
                  <div className='byline'>
                    <FaUser /> <span>{data?.byline}</span>
                  </div>
                )}
                <div className='meta'>
                  {showTime && (
                    <p className='date-time'>
                      <BiCalendar /> <span> {useDateUpdate(data?.published_at, false)} </span>
                    </p>
                  )}
                  {showCountview && (
                    <>
                      <p className='pageviews'>
                        <AiOutlineEye />
                        <span>{data.pageviews}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  &.card-h {
    margin-top: 15px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 8px;
    }
  }
  &:hover {
    h3,
    p {
      color: #ed1a3b !important;
    }
  }
  .card-h-most-view {
    display: flex;
    .img-scale-animate {
      padding: 0 10px;
      text-align: center;
      position: relative;
      h3 {
        border-bottom: 1px solid #ed1a3b;
        font-size: 22px;
      }
    }
  }
  .card {
    h3,
    p {
      transition: all 0.5s ease-in-out;
      color: black;
    }
    &.--content {
      padding: 0px 10px;
      width: 100%;
      max-width: 100%;
      .title {
        color: rgb(88 88 88);
      }
      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .meta-contents {
    margin-top: 5px;
    .byline {
      color: #808080;
      font-size: 12px;
    }
    .meta {
      margin-top: 5px;
      display: flex;
      .date-time {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 12px;
        color: #808080;
      }
      .pageviews {
        margin-left: 10px;
        font-size: 12px;
        color: #808080;
        svg {
          vertical-align: middle;
        }
        span {
          padding-left: 5px;
        }
      }
    }
  }
`;

export default CardH;
