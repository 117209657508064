import _ from 'lodash';
import React from 'react';
// import CardOverlay from 'src/components/cards/card-overlay';
import styled from 'styled-components';
import Carousel3d from './widget/carousel-3d';

function SectionOne({ dataSet, data }: any) {
  return (
    <SectionWrapper className='section-pride-1'>
      <div className='container'>
        <img className='title-image' src={_.find(dataSet, { key: 'title' })?.value} />
        <div className='title-text'>{_.find(dataSet, { key: 'text-title' })?.value}</div>
        <Carousel3d slides={data} />
      </div>
      <img className='section-background' src={_.find(dataSet, { key: 'background-image' })?.value} />
    </SectionWrapper>
  );
}
const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding: 60px 0;
  overflow: hidden;
  .section-background {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    @media (min-width: 1800px) {
      top: 20%;
    }
  }
  .container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    @media (max-width: 767px) {
      gap: 10px;
    }
    .react-3d-carousel {
      width: 100%;
    }
    .title-text {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      padding: 0 18vw;
      @media (max-width: 767px) {
        margin-top: 20px;
        font-size: 16px;
        padding: 0 10vw;
      }
      @media (max-width: 475px) {
        font-size: 14px;
      }
      @media (min-width: 1600px) {
        padding: 0 250px;
      }
    }
    .title-image {
      width: 60%;
      @media (max-width: 475px) {
        width: 80%;
      }
    }
    .card-overlay {
      width: 100%;
      .img-scale-animate {
        border-radius: 20px;
      }
      .card {
        border-radius: 0 0 20px 20px;
      }
    }
  }
`;
export default SectionOne;
