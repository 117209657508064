import _ from 'lodash';
import styled from 'styled-components';

import { FaUser } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';

import { useDateUpdate } from 'src/utils/helper';

import { Datum } from 'src/modules/sections/section-1';
import dynamic from 'next/dynamic';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Image = dynamic(import('src/components/image'));
const WidgetPlayBtn = dynamic(import('src/components/widgets/widget-play-btn'));

type Props = {
  data: Datum;
  showTitle: boolean;
  showBlurb: boolean;
  showByline: boolean;
  showTime: boolean;
  external: boolean;
  eventDataLayer: any;
  className?: string;
  showBlurbTop?: boolean;
};

const CardOverlay = ({ data, showTitle = true, showBlurb = true, showByline = true, showTime = true, external, eventDataLayer = {}, className = '', showBlurbTop = false }: Props) => {
  if (_.isEmpty(data)) return null;
  return (
    <>
      <CardWrapper className='card-overlay'>
        <a
          tabIndex={-1}
          href={data?.link}
          // title={data?.title}
          //! DATALAYER
          onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
        >
          {showBlurbTop ? <div className='blurb-on-top line-clamp --3'>{data?.blurb}</div> : null}
          <div className='img-scale-animate mb-2'>
            <Image image={data?.image} title={data?.title} external={external} />
            {data?.is_video && <WidgetPlayBtn />}
          </div>
          <div className={`card --content ${className}`}>
            {showTitle && <h3 className={'title text-excerpt-2'}>{data?.title}</h3>}
            {showBlurb && <p className='blurb text-excerpt-2'>{data?.blurb}</p>}
            <div className='meta-contents'>
              {showByline && !_.isEmpty(data?.byline) && (
                <div className='byline'>
                  <FaUser /> <span>{data?.byline}</span>
                </div>
              )}
              <div className='meta'>
                {showTime && (
                  <p className='date-time'>
                    <BiCalendar />
                    <span> {useDateUpdate(data?.published_at, false)} </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </a>
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  .blurb-on-top {
    padding: 0 30px;
    position: absolute;
    top: -100px;
    z-index: 100;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    @media (max-width: 900px) {
      font-size: 14px;
      top: -90px;
    }
    @media (max-width: 475px) {
      top: -80px;
    }
  }
  &.card-overlay {
    margin: 15px 0;
  }
  .img-scale-animate {
    position: relative;
    &:hover {
      h3,
      p {
        color: #ed1a3b !important;
      }
    }
  }
  .card {
    position: absolute;
    left: 0;
    bottom: 0;
    h3,
    p {
      transition: all 0.5s ease-in-out;
      color: white;
    }
    &.tppa-showtitle {
      z-index: 1;
    }
    &.--content {
      padding: 15px 20px;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      max-width: 100%;
      h3 {
        font-size: 22px;
        font-weight: 500;

        @media (max-width: 1023px) {
          font-size: 18px;
        }
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
  }
`;

export default CardOverlay;
