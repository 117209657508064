import _ from 'lodash';
import React from 'react';
import Carousels from 'src/components/carousels';
import styled from 'styled-components';

function SectionFour({ dataSet }: any) {
  const dataImage = dataSet.filter((item: any) => item.key.startsWith('image')).map((item: any) => item.key.slice(-1));
  const dataLink = dataSet.filter((item: any) => item.key.startsWith('link')).map((item: any) => item.key.slice(-1));

  return (
    <SectionWrapper className='container'>
      <img className='title-4' src={_.find(dataSet, { key: 'title' })?.value} />
      {/* <div className='image-wrapper'> */}
      <Carousels infinite={_.intersection(dataImage, dataLink).length > 3} slideShow={3} className='image-wrapper'>
        {_.intersection(dataImage, dataLink).map((ele: any, index: number) => (
          <a href={dataSet.filter((item: any) => item.key === `link-${ele}`)[0].value}>
            <img key={index} src={dataSet.filter((item: any) => item.key === `image-${ele}`)[0].value} />
          </a>
        ))}
      </Carousels>
      {/* </div> */}
    </SectionWrapper>
  );
}
const SectionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  .title-4 {
    position: absolute;
    top: -40px;
    width: 25%;
    @media (max-width: 992px) {
      top: -30px;
      width: 35%;
    }
    @media (max-width: 767px) {
      top: -25px;
      width: 40%;
    }
  }
  .overflow-hidden.carousel {
    width: 600px;
    max-width: 100%;
    padding-top: 70px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      width: 200px;
      padding-top: 60px;
      padding-bottom: 0px;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
  .carousel {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 40px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
    @media (max-width: 475px) {
      padding-top: 50px;
    }
    .slick-dots {
      bottom: -40px;
    }
    a {
      width: 30%;
      padding: 0 25px;
      @media (max-width: 767px) {
        padding: 0 15px;
      }
      img {
        width: 100%;
      }
    }
  }
`;

export default SectionFour;
