import dynamic from 'next/dynamic';
import styled from 'styled-components';

const Carousel = dynamic(import('react-slick'));

const Carousels = ({
  children,
  dots = true,
  slideShow = 1,
  infinite = false,
  mobileSlideShow = 1,
  autoplay = true,
  pauseOnHover = true,
  autoplaySpeed = 3000,
  vertical = false,
  mobileSlideShowTwo = 1,
  center = false,
  arrows = false
}: any) => {
  const settings = {
    dots: dots,
    speed: 1000,
    infinite: infinite,
    slidesToShow: slideShow || 3,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    pauseOnHover: pauseOnHover,
    vertical: vertical,
    verticalSwiping: vertical,
    arrows: arrows,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileSlideShow || slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: mobileSlideShow || slideShow || 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: vertical ? (mobileSlideShow || 3) : (mobileSlideShowTwo || 1),
          slidesToScroll: 1,
          dots: true,
          className: center && 'center',
          centerMode: center && true,
          centerPadding: center && '20px'
        }
      }
    ]
  };
  return (
    <CarouselWrapper className='overflow-hidden carousel'>
      <Carousel {...settings}>
        {children}
      </Carousel>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
&.carousel {
  padding: 0 2px 0 2px;
}
.slick-slider {
  .slick-prev, .slick-next {
    &:before {
      color: rgb(220 21 53);
      font-size: 28px;
    }
  }
  .slick-prev {
    z-index: 1;
    left:0 ;
  }
  .slick-next {
    z-index: 1;
    right:7px;
  }
}
.slick-dots {
  /* bottom: -30px; */
  .slick-active {
    button:before {
      color: rgba(0, 0, 0, 1);
    }
  }
    li.slick-active {
      button:before {
        background-color: rgb(220 21 53) ;
        opacity: 1;
      }
    }
  button:before {
    content:'';
    color: rgba(0, 0, 0, 0.8);
    background-color: rgb(191 191 191) ;
    border-radius: 100%;
    opacity: 1;
    width: 15px;
    height: 15px;
  }
}
.slick-track {
  overflow: hidden;
}
`;

export default Carousels;
