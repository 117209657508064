import _ from 'lodash';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { APP_IMG } from 'src/constants';
import { GET } from 'src/services';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { convertDataLists, convertDatasets } from 'src/utils/datasets';
import useResponsive from 'src/utils/device';
import { convertObjPath, timestamp } from 'src/utils/helper';
import styled from 'styled-components';
type Props = {
    data?: any;
    hightligth?: any;
};
const SectionDiversity: NextPage<Props> = ({ data, hightligth }) => {

    if (_.isEmpty(data)) return null;

    const router = useRouter();
    const [resDataSection, setResDataSection] = useState<any>();
    const responsive = useResponsive();

    const allData = {
        ...convertDataLists(data, ['description-', 'partner-']),
        header: convertDatasets(data, 'header', true),
        headerMB: convertDatasets(data, 'header-mb', true),
        block1: convertDatasets(data, 'block-1', true),
        categoryNews: convertDatasets(data, 'categoryNews', true),
        tagNews: convertDatasets(data, 'tagNews', true),
        link: convertDatasets(data, 'ลิงค์', true),
        partnership: convertDatasets(data, 'partnership', true),
        limit: convertDatasets(data, 'limit', true),
        bgPartner: convertDatasets(data, 'bg-partner', true)
    };

    useEffect(() => {
        (async () => {
            try {
                if (!_.isEmpty(allData.categoryNews)) {
                    const res: any = await GET(`/api/v1.0/categories/${allData.categoryNews}?limit=${allData.limit}`, false, true);
                    if (!_.isEmpty(res)) setResDataSection(res);
                } else if (!_.isEmpty(allData.tagNews)) {
                    const res: any = await GET(`/api/search/tag${convertObjPath({ name: allData.tagNews })}&limit=6`, false, true);
                    if (!_.isEmpty(res)) setResDataSection(res);
                }
            } catch (err: any) {
                console.error(`${timestamp()} ==========> EVENT_ELECTION-2566 ERROR :`, err?.message);
            }
        })();
    }, []);
    console.log(resDataSection?.data);

    return (
        <SectionWrapper id='section-diversity' background={allData.bgPartner}>
            {responsive.isMobile ? <img src={allData.headerMB} alt='DIVERSITY' title='DIVERSITY' /> : <img src={allData.header} alt='DIVERSITY' title='DIVERSITY' />}

            <div className='container grid-block'>
                <div className='block-1'>
                    <img src={allData.block1} alt='Pride is for everyone' title='Pride is for everyone' />
                </div>
                <div className='block-2'>
                    {!_.isEmpty(hightligth) &&
                        <a
                            className='card-hightligth'
                            target='_self'
                            aria-label={hightligth?.[0]?.title}
                            href={hightligth?.[0]?.link}
                            //! DATALAYER
                            onClick={() => sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router.pathname,
                                position: 'ใต้:ads-billboard',
                                section: 'section-diversity',
                                data: {
                                    block: '1',
                                    heading: 'DIVERSITY',
                                    title: hightligth?.[0]?.title,
                                    index: 1
                                }
                            })}>
                            <img src={`${APP_IMG}${hightligth?.[0]?.image}`} alt={hightligth?.[0]?.title} title={hightligth?.[0]?.title} width={425} height={425} />
                            <h3 className='text-hightligth'>
                                {hightligth?.[0]?.title}
                            </h3>
                            <p className='text-blurb'>
                                {hightligth?.[0]?.blurb}
                            </p>
                        </a>}
                </div>
            </div>

            <div className='container grid-news'>

                {
                    _.map(resDataSection?.data, (item, index) =>
                        <a
                            key={index}
                            className='card-item'
                            target='_self'
                            aria-label={item.title}
                            href={item.link}
                            //! DATALAYER
                            onClick={() => sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router.pathname,
                                section: 'section-diversity',
                                position: 'ใต้:ads-billboard',
                                data: {
                                    block: '1',
                                    heading: `${item?.section?.en}:${item?.subCategory?.en}`,
                                    title: item.title,
                                    index: index
                                }
                            })}>
                            <img className='image-card' src={`${APP_IMG}${item?.image}`} alt={item?.title} title={item?.title} width={425} height={425} />
                        </a>
                    )
                }
            </div>
            <div className='container footer'>
                <a href={allData.link}
                    target='_self'
                    aria-label='ดูทั้งหมด'
                    className='btn-more'
                    //! DATALAYER
                    onClick={() => sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router.pathname,
                        section: 'section-diversity',
                        position: 'ใต้:ads-billboard',
                        data: {
                            block: '1',
                            heading: 'DIVERSITY',
                            title: 'ดูทั้งหมด',
                        }
                    })} >
                    ดูทั้งหมด
                </a>
            </div>
            <div className='partner-block'>
                <div className='container'>
                    <img className='head-partnership' src={allData.partnership} width={600} height={'100%'} alt='partnership' title='partnership' />
                    <div className='card-list'>
                        {
                            _.map(allData.partner, (item, index) => <div key={index} className='card-item'>
                                <img src={`${item}`} width={200} height={'100%'} alt='the people' title='the people' />
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

const SectionWrapper = styled.section<{
    background: any;
}>`
background: #000;
img {
    max-width: 100%;
}


.footer {
width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;

a {
    background: red;

    padding: 8px 20px;
    border-radius: 20px;
    color: white;
    :hover {
        opacity: 0.7;
    }
}}

.head-partnership {
    margin: auto;
    display: flex;
    padding-bottom: 4rem;
    @media (max-width: 768px) {
        padding-bottom: 1rem;
        max-width: 80%;
    }
}
.partner-block {
    display: flex;
    background: url(${props => props.background});
    height: 500px;
    background-size: cover;
    background-position: top center;
    @media (max-width: 768px) {
        height: 200px;
    }
    .card-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        .card-item {
            text-align: center;
            @media (max-width: 768px) {
                max-width: 60%;
                margin: auto;
            }
        }
    }
    .btn-more {
        background: red;
        width: fit-content;
        padding: 8px 15px;
        height: 40px;
        border-radius: 20px;
        color: #FFF;
        font-weight: 600;
    }
}

.grid-block {
    display: grid;
    grid-template-columns: 2fr 1fr;
    .block-1 {
    display: block;
    text-align: start;

        .text-desc {
            color: #FFF
        }
        .text-lists {
            padding: 2re;
        }
    }
    .block-2 {
        display: flex;
        .card-hightligth {
            padding-top: 5rem;
            .text-hightligth {
                text-align: start;
                color: #FFF;
                font-size: 2rem;
                padding-top: 15px;
            }

            .text-blurb {
                text-align: start;
                color: #FFF;
                font-size: 1rem;
                padding-top: 15px;

            }
            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                height: auto;

            }
            &:hover {
               transition: all 0.5s ease-in-out;
               filter: grayscale(1);
            }
        }
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr; 
        .card-hightligth  {
            display: block;
            padding: 1rem;
            .text-hightligth,  .text-blurb {
                text-align: center;
            }
        }
    }
}

.grid-news {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-auto-rows: 400px;
    grid-gap:0;
    .card-item {
        text-align: center;
        :nth-child(1) {
              
                    filter: brightness(50%) saturate(0%) invert(20%)  sepia(70%) saturate(1000%) hue-rotate(310deg) contrast(70%);
                
            }
            :nth-child(2) {
    
                    filter:brightness(50%) saturate(0%)  invert(20%) sepia(70%) saturate(1941%) hue-rotate(354deg) contrast(70%);
                
            }
            :nth-child(3) {
           
                    filter: brightness(80%) saturate(0%)  invert(20%) sepia(70%) saturate(800%) hue-rotate(354deg) contrast(70%);
       
            }
            :nth-child(4) {
                filter: brightness(30%) saturate(0%) invert(20%) sepia(22%) saturate(400%) hue-rotate(122deg) contrast(110%);
           
            }
            :nth-child(5) {
                filter: brightness(30%) saturate(0%) invert(20%) sepia(22%) saturate(800%) hue-rotate(172deg) contrast(110%);
            }
            :nth-child(6) {
                filter: brightness(20%) saturate(0%) invert(20%) sepia(22%) saturate(1500%) hue-rotate(230deg) contrast(110%);
          
            }
        &:hover {
               transition: all 0.25ms ease-in-out;
                filter: none;
            }
          
    }
    img {
        aspect-ratio: 1/1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      

    }
    @media (max-width: 1024px) {
        grid-auto-rows: 300px; 
    }
    @media (max-width: 768px) {
        grid-auto-rows: auto;
     
    }
}

`;
export default SectionDiversity;
